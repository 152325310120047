<template>
  <div class="bg-white">
    <div class="p-3">
      <HeaderPanel
        title="Inactive Customer"
        placeholder="Search Name, Telephone, Email"
        :hasFilter="false"
        :hasDropdown="false"
        :filter="filter"
        class="mb-3"
        @search="onSearch"
      >
        <template #extra-button>
          <b-button
            variant="custom-outline-primary"
            class="btn-save mr-2"
            @click="$refs.customerModal.show()"
          >
            <font-awesome-icon icon="user-plus" class="icon" />
            Add
          </b-button>
        </template>
      </HeaderPanel>

      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(first_name_th)="data">
              <router-link
                :to="`/report/customer/${data.item.user_guid}`"
                style="line-height: 15px"
              >
                <div class="text-bold text-underline">
                  {{ data.item.first_name_th }} {{ data.item.last_name_th }}
                </div>
              </router-link>
              <div class="f-12 text-light-black">{{ data.item.member_id }}</div>

              <div class="f-12 text-light-black">
                {{
                  data.item.is_company == 0 || !data.item.is_company
                    ? "Personal"
                    : `Company  ${
                        data.item.company_information
                          ? data.item.company_information == "จดทะเบียน"
                            ? " : Register"
                            : ": Not Register"
                          : ""
                      }`
                }}
              </div>
            </template>
            <template v-slot:cell(member_level_name)="data">
              <b-row>
                <b-col class="text-right p-0" cols="3">
                  <img
                    :src="data.item.member_level_icon"
                    class="icon-level"
                    @error="handleImageSrc"
                  />
                </b-col>
                <b-col class="text-left" cols="9">
                  {{ data.item.member_level_name }}
                </b-col>
              </b-row>
            </template>
            <template v-slot:cell(created_time)="data">
              <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
              <div class="time-color">
                {{ data.item.created_time | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(email)="data">
              <div class="text-left">
                {{ data.item.email ? data.item.email : "-" }}
              </div>
            </template>
            <template v-slot:cell(birthday)="data">
              <div class="d-flex justify-content-center">
                <span v-if="chkBirthday(data.item.birthday)">
                  {{ data.item.birthday }}
                </span>
                <span v-else-if="data.item.has_birthday_year">{{
                  $moment(data.item.birthday).format("DD/MM/YYYY")
                }}</span>
                <span v-else-if="!data.item.has_birthday_year">{{
                  $moment(data.item.birthday).format("DD/MM")
                }}</span>
              </div>
            </template>
            <template v-slot:cell(telephone)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ convertTelePhone(data.item.telephone) }}
                <template v-if="data.item.optional_telephone">
                  <span
                    class="fa-stack small"
                    :id="'tooltip-target-' + data.item.user_guid"
                    style="font-size: 0.6rem"
                  >
                    <font-awesome-icon icon="circle" class="fa-stack-2x" />
                    <font-awesome-icon
                      icon="phone-alt"
                      class="fa-stack-1x text-white"
                    />
                  </span>

                  <b-tooltip
                    :target="'tooltip-target-' + data.item.user_guid"
                    triggers="hover"
                    custom-class="optional-container"
                    placement="right"
                  >
                    Optional Telephone <br />
                    <template
                      v-for="(
                        optional_telephone, index
                      ) of data.item.optional_telephone.split(',')"
                    >
                      <div :key="optional_telephone + index">
                        {{ optional_telephone }}
                      </div>
                    </template>
                  </b-tooltip>
                </template>
              </div>
            </template>
            <template v-slot:cell(point_total)="data">
              <div>{{ data.item.point_total | numeral("0,0") }}</div>
            </template>
            <template v-slot:cell(grand_total)="data">
              <div>{{ data.item.grand_total | numeral("0,0.00") }}</div>
            </template>
            <template v-slot:cell(is_consent)="data">
              <div
                :class="
                  data.item.is_consent == 0
                    ? 'text-error'
                    : data.item.is_consent == 1
                    ? 'text-success'
                    : 'text-warning'
                "
              >
                {{
                  data.item.is_consent == 0
                    ? "No"
                    : data.item.is_consent == 1
                    ? "Yes"
                    : data.item.is_consent == 2
                    ? "Wating For Approve"
                    : data.item.is_consent == 3
                    ? "Fail Consent 1"
                    : data.item.is_consent == 4
                    ? "Fail Consent 2"
                    : data.item.is_consent == 5
                    ? "Fail Consent 3"
                    : data.item.is_consent == 6
                    ? "Relieve"
                    : "Suspend"
                }}
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <b-button
                variant="custom-link"
                @click="deleteCustomer(data.item.id, data.item.user_guid)"
              >
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </b-button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangePerpage"
      />
      <ModalCustomerList
        :ref="`customerModal`"
        @select="handleSelectedCustomer"
        :selectedCustomer="selectedCustomer"
      />
    </div>
  </div>
</template>

<script>
import ModalCustomerList from "@/components/ticket/activity/ModalCustomerList";
export default {
  components: { ModalCustomerList },
  data() {
    return {
      filter: {
        branch_id: this.$route.params.id,
        search: "",
        page: 1,
        take: 10,
      },
      fields: [
        {
          key: "first_name_th",
          label: "Customer Name",
          class: "text-left",
        },
        {
          key: "member_level_name",
          label: "Tier",
        },

        {
          key: "telephone",
          label: "Telephone",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "point_total",
          label: "Point",
        },
        {
          key: "created_time",
          label: "Register Date",
        },
        {
          key: "grand_total",
          label: "Grand Total",
        },
        {
          key: "is_consent",
          label: "Consent Status",
        },
        {
          key: "action",
          label: "",
        },
      ],
      selectedCustomer: [],
      isBusy: false,
      rows: 0,
      items: [],
    };
  },
  created() {
    this.getList();
    this.getCustomerList();
  },
  methods: {
    async getCustomerList() {
      const res = await this.axios(
        `branch/BranchInactiveCustomer/ListUserGuid/${this.$route.params.id}`
      );

      this.selectedCustomer = res.data.detail.list_user_guid;
    },
    async getList() {
      this.isBusy = true;
      const res = await this.axios.post(
        "/branch/BranchInactiveCustomer/GetList",
        this.filter
      );
      this.items = res.data.detail.detail;
      this.rows = res.data.detail.total_count;
      this.isBusy = false;
      this.selectedCustomer = res.data.detail.detail.map((el) => el.user_guid);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    handleSelectedCustomer(customer) {
      this.addCustomer(customer.user_guid);
    },
    async addCustomer(user_guid) {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post("/branch/BranchInactiveCustomer", {
        branch_id: this.$route.params.id,
        user_guid: user_guid,
      });
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1)
        this.successAlert(), this.getList(), this.getCustomerList();
      else this.errorAlert(res.data.message);
    },
    async deleteCustomer(id, user_guid) {
      try {
        this.$bus.$emit("showLoading");
        const res = await this.axios.delete(
          `/branch/BranchInactiveCustomer/${id}`
        );
        this.$bus.$emit("hideLoading");
        if (res.data.result == 1)
          this.successAlert(), this.getList(), this.getCustomerList();
        else this.errorAlert(res.data.message);
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    onSearch() {
      this.filter.page = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 23px;
  max-height: 23px;
}
</style>
